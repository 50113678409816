export default {
    quizzing: {
        header: 'Introduction',
        body: 'Words you\'ve added from vocabulary lists appear here on the lessons page.<br />You can add them to your spaced repetition cycle by quizzing yourself on them. If you answer a question wrongly during a quiz, it will be returned to the lessons page where you can try again.',
        headerStyle: 'bg-primary',
        bodyStyle: 'bg-light bg-gradient text-dark'
    },
    quizzing2: {
        header: 'Setup a Quiz',
        body: 'To quiz yourself on an item:<br>1. Select on or more cards by clicking them.<br>2. Click the <i class="fas fa-check text-dark"></i> button to open a dialog.<br>3. Finally click \'to quiz\' when you\'ve learned the selected words.',
        headerStyle: 'bg-primary',
        bodyStyle: 'bg-light bg-gradient text-dark'
    },
    searchFunctionality: {
        header: 'The Search Bar',
        body: 'The lessons page also contains a search bar. Here you can:<br>1. Freely look up and add cards.<br>2. Access custom flash cards and kanji adding by clicking the <i class="fas fa-ellipsis-v"></i> button'
    },
}
