<template>
    <span class="badge"
    :class="classesToUse"
    :disabled="this.disabled"
    @mouseover="hovering = true"
    @mouseleave="hovering = false"
    @click="$emit('clicked')"
    ><slot></slot></span>
</template>

<script>
export default {
    name: 'AddKanjiBadge',
    emits: ['clicked'],
    props: [
        'activeClasses',
        'inactiveClasses',
        'active',
        'disabledClasses',
        'disabled',
        'hoveringClasses',
    ],
    computed: {
        classesToUse() {
            const classes = []
            if (this.disabled) {
                return this.disabledClasses || ''
            }
            if (this.hovering) {
                classes.push(this.hoveringClasses ? `--hovering ${this.hoveringClasses}` : `--hovering --def`)
            }
            if (this.active) {
                classes.push(this.activeClasses || '')
            }
            if (!this.active) {
                classes.push(this.inactiveClasses || '')
            }
            return classes.join(' ')
        }
    },
    data() {
        return {
            hovering: false,
        }
    }
}
</script>

<style lang="scss" scoped>
.--hovering {
    cursor: pointer;
    &.--def {
        filter: brightness(1.35);
    }
}
</style>
