export default ({
    direction,
    current,
    step,
}, arrayLength) => {
    const newPage = direction === 'right' ? current + step : current - step
    if (direction === 'right') {
        return newPage >= arrayLength ? 0 : newPage
    }
    // left
    if (newPage < 0) {
        // simulate going through
        let currentPosition = 0
        while (currentPosition + step < arrayLength) {
            currentPosition += step
        }
        return currentPosition
    }
    return newPage
}
