export default () => {
    const BREAKPOINTS = {
        small: 576,
        medium: 768,
        large: 992
    }
    const currentWidth = window.innerWidth
    if (currentWidth <= BREAKPOINTS.small) {
        return 'SM'
    }
    if (currentWidth < BREAKPOINTS.medium) {
        return 'MD'
    }
    if (currentWidth < BREAKPOINTS.large) {
        return 'LG'
    }
    return 'XL'
}
