<template>
    <div class="errs">
        <div v-for="(error, index) in errors" :key="index">・{{error}}</div>
    </div>
</template>

<script>
export default {
    name: 'CustomWordErr',
    props: ['errors']
}
</script>

<style scoped>
.errs {
    color: red;
}
</style>
