<template>
    <portal to="modal">
        <div class="modal" tabindex="-1" v-if="showing" @mousedown="primeClickout" @mouseup="triggerClickout">
            <div class="modal-dialog" @mousedown.stop @mouseup.stop :class="modalPosition">
                <div class="modal-content">
                    <slot name="header">
                        <div class="modal-header">
                            <h5 class="modal-title">Modal title</h5>
                            <button type="button" class="btn-close clickable"></button>
                        </div>
                    </slot>
                    <slot name="body">
                        <div class="modal-body">
                            <p>Modal body text goes here.</p>
                        </div>
                    </slot>
                    <slot name="footer">
                        <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="hideModal">Close</button>
                        <button type="button" class="btn btn-primary">Save changes</button>
                    </div>
                    </slot>
                </div>
            </div>
        </div>
    </portal>
</template>

<script>

export default {
    props: ['showing', 'position', 'click-out'],
    data() {
        return {
            clickoutPrimed: false,
        }
    },
    computed: {
        modalPosition() {
            if (!this.position || this.position === 'center') return '--center'
            if (this.position === 'stable') return '--stable'
            return ''
        },
    },
    methods: {
        hideModal() {
            this.$emit('update:showing', false)
        },
        // prevent mousedown on modal -> mouseup on outside trigger modal close
        primeClickout() {
            this.clickoutPrimed = true
        },
        triggerClickout() {
            if (this.clickoutPrimed) this.$emit('click-out')
            this.clickoutPrimed = false
        }
    },
}
</script>

<style lang="scss" scoped>
.modal {
    display: block;
}
</style>
