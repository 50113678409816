<template>
<!-- items available -->
<div>
    <h2 style="font-size:1.5em;">
        <slot name="heading"></slot>
    </h2>
    <div class="pad-big d-flex tutorial">
    <!--selected options-->
        <div class="pad-small">
            <slot name="message"></slot>
        </div>
    </div>
    <div v-if="wordLen > flags.paginationLength || flags.pagination != 0" class="pad-big tutorial w-90">
        <div class="pad-small d-flex align-center">
            <div style="flex:1 1 1px"  class="clickable anim-shimmer" @click="$emit('prev-page')">
                <span class="fa-stack">
                    <i class="fa-stack-2x fas fa-square c-purple"></i>
                    <i :class="`fa-stack-1x ${!atFirstPage ? 'fas fa-angle-double-left' : 'fas fa-step-backward'}`"></i>
                </span>
            </div>
            <div v-tippy :content="`${wordLen} words total`">
                Showing <strong class="c-purple">{{flags.pagination+1}}</strong> -
                <strong class="c-purple">{{ paginationCalc }}</strong>
            </div>
                        <div style="flex:1 1 1px"  class="clickable anim-shimmer" @click="$emit('next-page')">
                <span class="fa-stack">
                    <i class="fa-stack-2x fas fa-square c-purple"></i>
                    <i :class="`fa-stack-1x ${!atLastPage ? 'fas fa-angle-double-right' : 'fas fa-step-forward'}`"></i>
                </span>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'Intro',
    props: [
        'flags',
        'words'
    ],
    computed: {
        paginationCalc() {
            const { flags, wordLen } = this
            return this.flags.pagination + this.flags.paginationLength > this.wordLen
                            ? this.wordLen
                            : this.flags.pagination + this.flags.paginationLength
        },
        atFirstPage() {
            const { flags, wordLen } = this
            if (flags.pagination === 0) return true
            return false
        },
        atLastPage() {
            const { flags, wordLen } = this
            if (flags.pagination + flags.paginationLength > wordLen) return true
            return false
        },
        wordLen() {
            return this.words.length || 0
        }
    }
}
</script>
