import { UseAPI, HandleRequestFail } from "@/assets/common"

export default ({
    arrayOfIds,
}) => UseAPI('/delete/', {
    method: "DELETE",
    body: JSON.stringify({
        items: arrayOfIds
    })
})
.catch((err) => Promise.reject(HandleRequestFail(err)))
