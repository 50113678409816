<template>
    <Editor
        :showingModal="showingModal"
        :mode="mode"
        :item="item"
        :words="words"
        :kanji-info="kanjiInfo"
        :flags="flags"
        :pronunciation="pronunciation"
        :reference="reference"
        :examples="examples"
        examples-enabled="1"
        v-on:start-trash="$emit('start-trash', $event)"
        v-on:start-delete="$emit('start-delete', $event)"
        v-on:click-out="$emit('click-out')"
    >
        <Corpus slot="examples"
            v-if="cardIsVocab && hasCorpusExamples"
            class="modal-footer align-left col-12 mx-auto"
            style="min-height:10rem"
            :examples="examplesToShow"
            :word="word"
        />
        <template v-slot:header-top>
            <slot name="header-top"></slot>
        </template>
        <template
        v-slot:controls-bottom
        >
            <template v-if="inQuizMode">
                <QuizControls class="modal-footer"
                v-on:cancel="$emit('cancel')"
                >
                    <template v-slot:confirm>
                        <router-link :to="url">
                            <button type="button" class="btn bg-success">To Quiz</button>
                        </router-link>
                    </template>
                </QuizControls>
            </template>
        </template>
    </Editor>
</template>

<script>
import {
    QuizControls,
    Editor,
} from '../index'
import {
    Corpus,
} from '@/components/Shared/index'

export default {
    components: {
        QuizControls,
        Editor,
        Corpus,
    },
    emits: [
        'trash',
        'delete',
        'left',
        'right',
        'close',
        'cancel',
    ],
    props: {
        // modal
        showingModal: {
            type: Boolean,
            required: true,
            default: false,
        },
        // editor main
        mode: {},
        item: {
            type: Object,
            required: true,
        },
        words: {
            type: Array,
            required: true,
        },
        kanjiInfo: {
            type: Array,
            default() { return [] }
        },
        flags: { // pass to Editor
            type: Object,
            required: true,
        },
        pronunciation: {
            type: Array,
            default() { return [] }
        },
        reference: {
            type: Array,
            default() { return [] }
        },
        examples: {
            type: Array,
            default() { return [] }
        },
        inQuizMode: {
            type: Boolean,
            default() { return false }
        },
        canNavigate: {
            type: Boolean,
            default() { return false }
        },
        // corpus
        word: {
            type: String,
            default() { return '' }
        },
        cardIsVocab: {
            type: Boolean,
            default() { return true }
        },
        hasCorpusExamples: {
            type: Boolean,
            default() { return false }
        },
        examplesToShow: {
            type: Array,
            default() { return [] }
        },
        url: {
            type: Object,
            default() {
                return { path: '/' }
            }
        },
    },
}
</script>

<style>

</style>
