<template>
    <portal to="alert">
        <Transition>
            <div class="alert" :class="finalClasses" role="alert"
            v-show="!hidden"
            >
                <slot>This is a primary alert—check it out!</slot>
                <AlertTimeoutProgress
                v-if="timeout"
                :timeout="timeoutDuration"
                v-on:anim-finish="signalDestroy"
                />
                <button v-if="canClose" @click="signalDestroy()"
                type="button" class="btn-close"></button>
            </div>
        </Transition>
    </portal>
</template>

<script>
import {
    AlertTimeoutProgress,
} from './index'

export default {
    props: ['classes', 'timeout', 'destroykey', 'canClose'],
    emits: ['destroy'],
    components: {
        AlertTimeoutProgress,
    },
    data() {
        return {
            timeoutId: 0,
            timeoutDuration: 3000,
            hidden: false,
        }
    },
    methods: {
        signalDestroy() {
            this.hidden = true
            this.$emit('destroy', this.destroykey)
        }
    },
    computed: {
        finalClasses() {
            // alert-dismissible
            const def = this.classes || 'alert-secondary'
            const dismiss = this.canClose ? 'alert-dismissible' : ''
            return [def, dismiss].join(' ')
        }
    }
}
</script>
