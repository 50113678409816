<template>
    <div>
        <span v-if="canNavigate" class="fa-stack clickable" @click="$emit('left')">
            <i class="fas fa-square fa-stack-2x c-purple"></i>
            <i class="fas fa-angle-double-left fa-stack-1x"></i>
        </span>
        <span v-if="canNavigate" class="fa-stack clickable" @click="$emit('right')">
            <i class="fas fa-square fa-stack-2x c-purple"></i>
            <i class="fas fa-angle-double-right fa-stack-1x"></i>
        </span>
        <span class="fa-stack clickable" aria-lable="Close" @click="$emit('close')">
            <i class="fas fa-square fa-stack-2x"></i>
            <i class="fas fa-times fa-stack-1x"></i>
        </span>
    </div>
</template>

<script>
export default {
    emits: ['left', 'right', 'close'],
    props: ['canNavigate']
}
</script>

<style>

</style>
