import {
    isKanji,
} from 'wanakana'

export default (str) => {
    const kanjiExtracted = []
    for (let i = 0; i < str.length; i += 1) {
        const char = str[i]
        if (isKanji(char)) kanjiExtracted.push(char)
    }
    return kanjiExtracted
}
