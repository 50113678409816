<template>
    <div class="progress" style="height: 8px;">
        <div class="progress-bar bg-info"
        role="progressbar"
        :style="length"
        ></div>
    </div>
</template>

<script>
// aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
export default {
    emits: ['anim-finish'],
    props: ['timeout', 'destroykey'],
    data() {
        return {
            progress: 0,
        }
    },
    computed: {
        length() {
            return {
                width: `${this.progress}%`,
            }
        }
    },
    mounted() {
        this.progress = 0
        window.setTimeout(() => {
            this.startAnim()
            this.startEndTimer()
        }, 100)
    },
    methods: {
        startAnim() {
            this.progress = 100
        },
        startEndTimer() {
            window.setTimeout(this.sendEndSignal, 3000)
        },
        sendEndSignal() {
            this.$nextTick(() => {
                this.$emit('anim-finish')
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.progress-bar {
    width: 0;
    transition: 2s all ease-out 1s;
}
</style>
