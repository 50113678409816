<template>
    <div>
        <slot name="cancel">
            <button type="button" class="btn bg-secondary" @click="$emit('cancel')">Cancel
            </button>
        </slot>
        <slot name="confirm">
            <button type="button" class="btn bg-success">To Quiz</button>
        </slot>
    </div>
</template>

<script>
export default {
    props: ['inQuiz'],
    emits: ['cancel', 'close-modal']
}
</script>

<style>

</style>
