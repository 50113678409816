class MapPolyfill {
    ordering = []

    data = {}

    length = 0

    get(key) {
        return this.data[key]
    }

    getKeyFromIndex(index) {
        const key = this.ordering[index]
        return key
    }

    getFromIndex(index) {
        const key = this.getKeyFromIndex(index)
        if (!key) return null
        return this.get(key)
    }

    // unsafely add additional items
    // use for initial set because its fast
    // o(1) complexity
    appendTrust(value, key, {
        write = false
    } = {}) {
        this.ordering.push(key)
        // do not use if adding as reactive prop to Vue
        if (write) this.data[key] = value
    }

    // safely add additional items
    // n^m time complexity
    append(value, key, {
        write = false,
        overwrite = false,
        reorder = false,
    } = {}) {
        // check if it exists
        const alreadyExists = this.get(key)
        // abort
        if (alreadyExists && overwrite === false) return
        // possibly change order array if already exists
        if (alreadyExists) {
            const foundIndex = this.ordering.findIndex((anItem) => anItem === key)
            if (foundIndex !== -1) {
                this.ordering.splice(foundIndex, 1)
            }
            if (reorder === true) this.pushOrdering(key)
        } else {
            this.pushOrdering(key)
        }
        // possibly write (skip this step if using in Vue, which you are)
        if (write) this.data[key] = value
    }

    pushOrdering(key) {
        this.ordering.push(key)
        this.length += 1
    }

    // translate array methods so that you access the data keys in order
    forEach(func) {
        return this.ordering.forEach((aKey, index) => {
            const value = this.get(aKey)
            return func(value, index)
        })
    }

    filter(func) {
        const returnedArray = []
        this.ordering.forEach((aKey, index) => {
            const value = this.get(aKey)
            if (func(value, index)) returnedArray.push(value)
        })
        return returnedArray
    }
}

export default MapPolyfill
