<template>
    <!--if has examples-->
    <div v-if="examples.length > 0">
        <div class="marg-small w-100">
            <h2 style="font-size:1.2em;" class="d-inline">Examples </h2>
            <i class="far fa-question-circle cursor-help" v-tippy content="Example sentences are taken from texts and are meant to be illustrative of a word's possible uses. Please be aware that the sentences below could not be indicative of practical use (in the case of sarcasm, purposeful misuse, slang, etc.). Please be aware that words may be improperly parsed (in the case of names, etc.). Just be cautious."></i>
        </div>
        <div class="row w-100" v-for="(items, indx) in shownSentences" :key="indx">
            <div class="col-12 col-md">
                <span lang="ja" v-html="items.html"></span>
                <br />
                <div class="marg-small"><span class="badge bg-secondary" title="source">{{items.source}}</span></div>
            </div>
            <!-- scoring removed -->
        </div>
    </div>
    <div v-else>
        <div class="marg-small">
            <h2 style="font-size:1.2em;" class="d-inline">No example sentences found</h2>
        </div>
    </div>
</template>

<script>
export default {
    name: "Corpus",
      computed: {
          shownSentences() {
              const { word, examples } = this
              //    prefer sentences that match the word
              if (!word) return examples
              const filtered = examples.filter((i) => i.val === word)
              if (filtered.length === 0) return examples
              return filtered
          }
      },
      methods: {

      },
      props: {
          examples: { required: true, default() { return [] } },
          scoring: { required: false, default: null },
          word: { required: false, default: null }
      },
}
</script>

<style lang="sass" scoped>
</style>
