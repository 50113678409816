<template>
    <Modal :showing="showingModal" v-on:click-out="$emit('click-out')" position="stable">
        <template v-slot:header>
            <div>
                <slot name="controls-top"></slot>
                <slot name="header-top"></slot>
                <slot name="below-header"></slot>
            </div>
        </template>
        <template v-slot:body>
            <div>
                <!--modal body for vocab-->
                <div v-if="item.card_type == 'v'" class="modal-body" style="min-height:125px;">
                    <div v-for="(entry, indx) in item.entries" :key="indx">
                        <div class="w-100"></div>
                        <div class="d-flex">
                            <div>
                                <div lang="ja" class="badge clickable header-badge"
                                    :class="item.readings.split(',').includes(entry.reading) ? 'bg-purple' : 'bg-secondary'"
                                    @click="toggleReading(item, entry.reading)" v-tippy
                                    content="click to toggle reading as a valid answer">
                                    <span role="pronunciation"
                                        v-if="pronunciation.length !== 0 && pronunciation.map((i) => i.word).includes(item.word) && pronunciation.map((k) => wanakana.toHiragana(k.reading)).includes(entry.reading)">
                                        <!--kind of a copout to only show the first result when there could be multiple..-->
                                        <span v-html="pronun.pronunciation"
                                            v-for="(pronun, indx) in pronunciation.filter((i) => i.word === item.word).filter((k) => wanakana.toHiragana(k.reading) == entry.reading).slice(0, 1)"
                                            :key="indx + 'a'"></span>
                                    </span>
                                    <span v-else>{{ entry.reading }}</span>
                                </div>
                            </div>
                            <div style="min-width: 15em">
                                <span v-for="(subentries, subIndx) in entry.subentries" style="margin-right:.25em"
                                    :key="subIndx + 'b'">
                                    <span v-if="subIndx === 0 && indx === 0" v-tippy
                                        content="Pitch accent guide: bar marks a high pitch accent.<br />Down-arrow marks a drop in pitch accent.<br />Lower opacity characters are less pronounced (ex: sh-ta instead of shi-ta)<br />A nasal mark ° following カキクケコ signifies a nasal variant of ガキグゲゴ"
                                        class="badge bg-warning cursor-help"><i class="far fa-question-circle"></i></span>
                                    <span class="badge cursor-help" :class="item.readings.split(',').includes(entry.reading) ?
                                        subentries.pos.split(' ').includes('arch') ? 'bg-danger' : 'bg-info' :
                                        'bg-secondary'" v-tippy :content="subentries.pos"><i
                                            class="far fa-question-circle"></i></span>
                                    <span class="badge bg-secondary"
                                        v-for="(defs, indx) in subentries.definition.slice(0, 3)" :title="defs"
                                        :key="indx + 'c'">
                                        {{ defs.length > 33 ? defs.substring(0, 33) + '…' : defs }}
                                    </span>
                                    <span v-if="subentries.definition.length > 3" class="badge bg-secondary" v-tippy
                                        :content="subentries.definition.join(', ')">
                                        <i class="fa fa-ellipsis-h"></i>
                                    </span>
                                    <br>
                                </span>
                            </div>
                        </div>
                        <!--defs-->
                    </div>
                    <span class="badge bg-info header-badge">Synonyms</span>
                    <span v-if="item.synonyms" class="badge bg-secondary rm-button"
                        v-for="(syn, synIndx) in item.synonyms.split(';')" :key="synIndx + 'd'">
                        <span>{{ syn }}</span>
                        <i class="fas fa-times clickable" @click="removeSynonym(item, synIndx)"></i>
                    </span>
                    <span class="badge bg-success clickable" @click="addSynonym(item)">
                        Add New <i class="fas fa-plus"></i>
                    </span>
                </div>
                <!--modal body for kanji-->
                <div v-if="item.card_type == 'k'" class="modal-body" style="min-height:125px;">
                    <div v-if="onReadings.length > 0">
                        <span class="badge bg-info header-badge">On-readings</span>
                        <span lang="ja" class="badge bg-secondary" v-for="(items, indx) in onReadings" :key="indx + 'e'">
                            {{ items }}
                        </span>
                    </div>
                    <div v-if="kunReadings.length > 0">
                        <span class="badge bg-info header-badge">Kun-readings</span>
                        <span lang="ja" class="badge bg-secondary" v-for="(items, indx) in kunReadings" :key="indx + 'f'">
                            {{ items }}
                        </span>
                    </div>
                    <div>
                        <span class="badge bg-info header-badge">Definitions</span>
                        <span class="badge bg-secondary" v-for="(items, indx) in item.entries[0].subentries[0].definition"
                            :key="indx + 'g'">{{ items }}</span>
                    </div>
                    <div>
                        <span class="badge bg-info header-badge">Synonyms</span>
                        <span v-if="item.synonyms" class="badge bg-secondary"
                            v-for="(syn, synIndx) in item.synonyms.split(';')" :key="synIndx + 'h'">
                            <span>{{ syn }}</span><i class="fas fa-times clickable"
                                @click="removeSynonym(item, synIndx)"></i>
                        </span>
                        <span class="badge bg-success clickable" @click="addSynonym(item)">Add New <i
                                class="fas fa-plus"></i></span>
                    </div>
                </div>
                <!--notes-->
                <div v-if="'notes' in item" class="modal-body" style="display:flex; align-items: start;">
                    <span class="badge bg-info header-badge">Notes</span>
                    <span class="badge bg-info cursor-help" v-tippy
                        content="Notes will appear after you answer a question. You can enclose a word or phrase with * or ** to highlight certain phrases.<br/>Ex: This is a *highlighted phrase*. And **this** as well."><i
                            class="far fa-question-circle"></i></span>
                    <span v-if="item.notes && !flags.editingNotes" class="badge bg-secondary"
                        style="white-space: pre-wrap; overflow-wrap: anywhere; text-align:left;"
                        v-html="$convertNoteToHtml"></span>
                    <span v-if="!flags.editingNotes" class="badge bg-success clickable" @click="startNoteEdit(item)">
                        <i class="fas fa-edit" v-tippy content="Edit note"></i>
                    </span>
                    <textarea id="notes_field" v-show="flags.editingNotes" v-model="flags.editingNotesText" class="w-100"
                        style="margin-left:.5em;" maxlength="549" @blur="handleNotesChanges(item)" @keyup.stop></textarea>
                </div>
                <!--for reference words-->
                <div class="modal-body" style="border-top: 1px solid #dee2e6; line-height:2.2">
                    <span class="badge bg-info header-badge">Reference</span>
                    <span class="badge bg-info cursor-help" v-tippy
                        content="Reference words will appear in modals and during reviews. Use them for words you often get mixed up on, either because of similar readings or definitions.<br />Ex: 漏らす with 濡らす as a reference word"><i
                            class="far fa-question-circle"></i></span>
                    <span class="badge rm-button clickable"
                        :class="flags.selectedReference === items ? 'bg-danger' : 'bg-secondary'"
                        v-for="(items, indx) in reference.filter(i => i.cardId === item.id)"
                        @click="changeSelectedReference(items)" :key="indx + 'i'">
                        <span style="min-width: 5em">{{ items.word }}</span>
                        <i class="fas fa-times clickable" @click.prevent="removeReferenceWord(items.id, items.cardId)"></i>
                    </span>
                    <span class="badge bg-success clickable" @click="toggleReferenceInput()">
                        <div class="d-flex w-100" v-if="!showReferenceInput">
                            <span>Add Reference Word</span>
                            <i class="fas fa-plus" style="align-self: center; padding-left: 5px"></i>
                        </div>
                        <div class="d-flex w-100" v-else>
                            <div @click.stop>
                                <MiniSearch enabled="v" v-on:button-clicked="addReferenceWord($event.word, item.id)" />
                            </div>
                            <i class="fas fa-angle-double-left" style="align-self: center; padding-left: 5px"></i>
                        </div>
                    </span>
                </div>
                <div v-if="flags.selectedReference && 'word' in flags.selectedReference" class="modal-body"
                    style="border-top: 1px solid #dee2e6;">
                    <div v-for="(entries, indx) in flags.selectedReference.entries" :key="indx + 'j'">
                        <span class="badge bg-danger">{{ entries.reading }}</span>
                        <span v-for="(subs, indx) in entries.subentries" :key="indx + 'j'" style="margin-right:.25em">
                            <span class="badge bg-info cursor-help">
                                <i class="far fa-question-circle" v-tippy :content="subs.pos.toString()"></i>
                            </span>
                            <span class="badge bg-secondary" v-for="(defs, indx) in subs.definition.slice(0, 3)"
                                :title="defs" :key="indx + 'k'">
                                {{ defs.length > 33 ? defs.substring(0, 33) + '…' : defs }}
                            </span>
                            <span v-if="subs.definition.length > 3" class="badge bg-secondary" v-tippy
                                :content="subs.definition.join(', ')">
                                <i class="fa fa-ellipsis-h"></i>
                            </span>
                        </span>
                    </div>
                </div>
                <!--modal body for normal edits-->
            </div>
        </template>
        <template v-slot:footer>
            <div>
                <slot v-if="examplesToggled" name="examples"></slot>
                <slot name="controls-bottom"></slot>
                <slot name="hotkeys"></slot>
            </div>
        </template>
    </Modal>
</template>

<script>
import { isKatakana, toHiragana, isJapanese } from 'wanakana'
import C from '@/assets/common'
import {
    Modal,
} from '@/components/Shared/index'
import { readCustomMarkup } from '@/features/render'
import MiniSearch from '../Mini-Search.vue'

const wanakana = { isKatakana, toHiragana, isJapanese }
const {
    UseAPI, HandleRequestFail, SendUserAlert
} = C

export default {
    name: 'Editor',
    components: {
        MiniSearch,
        Modal,
    },
    created() {
        console.log('%cCreated editor', window.ConsoleStyles.createdComponent, this)
    },
    methods: {
        toggleReferenceInput() {
            this.showReferenceInput ? this.showReferenceInput = false : this.showReferenceInput = true
            if (this.showReferenceInput) {
                this.$nextTick(() => {
                    const miniSearchNode = document.getElementById('minisearch')
                    if (miniSearchNode) miniSearchNode.focus()
                })
            }
        },
        handleTrash(id) {
            this.$emit('start-trash', [id])
        },
        handleDelete(id) {
            this.$emit('start-delete', [id])
        },
        $hasExamplesSlot() {
            //  return !!this.$slots.examples
            const name = 'examples'
            return this.$slots[name] || this.$scopedSlots[name]
        },
        toggleReading(item, reading) {
            // Find if specified reading exists in entries
            const found = item.entries.find((entry) => entry.reading === reading)
            if (!found) {
                SendUserAlert('Thats just not a valid reading.', 'alert-warning')
                return false
            }
            // Check if item already exists in item readings and splice or push
            const indx = item.readings.split(',').findIndex((i) => i === found.reading)
            if (indx !== -1) {
                const splitReadings = item.readings.split(',').filter((i) => i)
                splitReadings.splice(indx, 1)
                item.readings = splitReadings.toString()
            } else {
                const itemReadings = item.readings.split(',').filter((i) => i)
                itemReadings.push(found.reading)
                item.readings = itemReadings.join(',')
                //  item.readings = `${item.readings},${found.reading}`
            }
            return UseAPI('/update/modify-read', {
                method: "POST", queryParameters: `val=${item.readings}&id=${item.id}`
            })
                .catch((dat) => {
                    SendUserAlert('error modifying data\ncheck console for logs', 'alert-danger')
                    HandleRequestFail(dat)
                    console.log('error log')
                    console.log(JSON.stringify(dat))
                });
        },
        addSynonym(item, val = prompt('Type a synonym')) {
            if (!val) { return false }
            const syn = item.synonyms.split(';')
            if (syn.find((i) => i === val)) {
                SendUserAlert('synonym already exists', 'alert-warning');
                return false
            }
            syn.length > 0 && syn[0] !== ''
                //  ? item.synonyms += (`;${val}`)
                ? item.synonyms = `${item.synonyms};${val}`
                : item.synonyms = val
            return UseAPI('/update/modify-syn', { method: "POST", queryParameters: `val=${item.synonyms}&id=${item.id}` })
                .then((res) => {
                    SendUserAlert('Added synonym', 'alert-success')
                })
                .catch((dat) => {
                    SendUserAlert('error modifying data\ncheck console for logs', 'alert-danger')
                    HandleRequestFail(dat)
                    console.log('error log')
                    console.log(JSON.stringify(dat))
                });
        },
        removeSynonym(item, synIndx) {
            const vm = this
            // check if it has at least one definition
            if (!item.entries[0].subentries[0].definition[0]) {
                SendUserAlert('Since word has no dictionary definitions, it must have at least one synonym.')
                return false
            }
            const expanded = item.synonyms.split(';')
            expanded.splice(synIndx, 1)
            if (expanded.length === 0 && item.source === 'custom_word') {
                SendUserAlert('Custom words need to keep at least one synonym', 'alert-danger')
                return false
            }
            item.synonyms = expanded.toString().replace(/,/g, ';')
            // commit using api
            return UseAPI('/update/modify-syn', { method: "POST", queryParameters: `val=${item.synonyms}&id=${item.id}` })
                .then((res) => {
                    SendUserAlert('Removed synonym', 'alert-success')
                })
                .catch((dat) => {
                    SendUserAlert('error modifying data\ncheck console for logs', 'alert-danger')
                    HandleRequestFail(dat)
                    console.log('error log')
                    console.log(JSON.stringify(dat))
                });
            //  return expanded.toString(';')
        },
        startNoteEdit(item) {
            console.log('found item', item)
            this.flags.editingNotes = true
            this.flags.editingNotesText = item.notes
            const field = document.getElementById('notes_field')
            const focusNotes = () => {
                field.focus()
            }
            setTimeout(focusNotes, 250)
        },
        handleNotesChanges(item) {
            const val = this.flags.editingNotesText
            const { notes } = this.item
            if ((!val && notes === "") || val === notes) {
                this.flags.editingNotes = false
                return false
            }
            if (!window.confirm('Save changes?')) {
                this.flags.editingNotes = false
                return false
            }
            item.notes = val
            this.flags.editingNotes = false
            return UseAPI('/update/notes', { method: "POST", body: JSON.stringify({ id: item.id, val }) })
                .then((dat) => {
                    console.log('dat is', dat)
                    if (
                        ('e' in dat || 'err' in dat)
                        && (dat.e || dat.err)
                    ) {
                        throw dat
                    } else {
                        SendUserAlert('Saved changes', 'alert-success')
                    }
                    console.log(dat)
                })
                .catch((dat) => {
                    SendUserAlert('There was an error modifying the word\ncheck console for error logs', 'alert-danger')
                    HandleRequestFail(dat)
                    console.log('failed to modify; error log')
                    console.log(JSON.stringify(dat))
                })
        },
        changeSelectedReference(items) {
            if (this.reference.find((i) => i.id === items.id)) {
                this.flags.selectedReference = items
            } else {
                this.flags.selectedReference = null
            }
            return this.flags.selectedReference
        },
        addReferenceWord(word, cardId) {
            //  const word = prompt('Type in a word (use kanji if possible)')
            if (!word || !cardId) { return false }
            if (wanakana.isJapanese(word) === false) {
                SendUserAlert('Reference words have to be in Japanese', 'alert-warning');
                return false
            }
            UseAPI('/create/reference-word', { method: "PUT", queryParameters: `word=${word}&cardId=${cardId}` })
                .then((res) => {
                    if (res.items.length === 0) {
                        SendUserAlert('Unable to find word in dictionary. Try typing it in kanji form if it\'s usually in kana?', 'alert-danger')
                        return false
                    }
                    SendUserAlert('Added reference word', 'alert-success')

                    this.reference.push(res.items[0])
                    return true
                })
                .catch((dat) => {
                    SendUserAlert('error modifying data\ncheck console for logs', 'alert-danger')
                    HandleRequestFail(dat)
                    console.log('error log')
                    console.log(JSON.stringify(dat))
                })
            // fetch request
            return console.log({ cardId, word })
        },
        removeReferenceWord(itemId, cardId) {
            const vm = this
            if (!itemId || !itemId) { return false }
            if (!this.reference.find((i) => i.id === itemId && i.cardId === cardId)) { return false }
            UseAPI('/delete/reference-word', { method: "DELETE", queryParameters: `id=${itemId}&cardId=${cardId}` })
                .catch((dat) => {
                    SendUserAlert('error modifying data\ncheck console for logs', 'alert-danger')
                    HandleRequestFail(dat)
                    console.log('error log')
                    console.log(JSON.stringify(dat))
                })
            const pos = vm.reference.findIndex((i) => i.cardId === cardId && i.id === itemId)
            if (pos !== -1) { vm.reference.splice(pos, 1) }
            vm.flags.selectedReference = null
            return console.log({ itemId, cardId })
        },
    },
    data() {
        return {
            wanakana,
            examplesToggled: true,
            showReferenceInput: false
        }
    },
    computed: {
        $cModalSource() {
            return this.item.source.replace('book_', '').replace(/_/g, ' ')
        },
        $convertNoteToHtml() {
            const { notes } = this.item
            if (!notes) return ""

            return readCustomMarkup(this.item.notes)
        },
        kunReadings() {
            return this.item.readings.split(',').filter((i) => !wanakana.isKatakana(i))
        },
        onReadings() {
            return this.item.readings.split(',').filter((i) => wanakana.isKatakana(i))
        }
    },
    props: {
        mode: {
            // SRS or lessons or...
            required: false
        },
        item: {
            type: Object,
            required: true,
        },
        kanjiInfo: {
            type: Array,
            required: true,
        },
        examples: { required: false, default: [] },
        examplesEnabled: { required: false, default: false },
        flags: { required: true },
        pronunciation: { required: true },
        reference: { required: true },
        words: { required: true },
        // SRS
        log: { required: false },
        ui: { required: false },
        showingModal: {
            required: true,
        },
    },
}
</script>

<style lang="sass" scoped>
.badge.header-badge
    min-width: 6em
    margin-right: .75em
.rm-button
    i
        margin-left: 2.5em
.badge
    line-height: 1.1
.break
  flex-basis: 100%
  height: 0
</style>
