const leftHandler = (obj) => {
    if (obj.flags.preQuiz) {
        obj.changeQuizIndex(false)
        return
    }
    obj.pagination('left')
}

const rightHandler = (obj) => {
    if (obj.flags.preQuiz) {
        obj.changeQuizIndex(true)
        return
    }
    obj.pagination('right')
}

function Hotkeys() {
    return {
        a: {
            keyup: () => {
                leftHandler(this)
            }
        },
        left: {
            keyup: () => {
                leftHandler(this)
            }
        },
        d: {
            keyup: () => {
                rightHandler(this)
            }
        },
        right: {
            keyup: () => {
                rightHandler(this)
            }
        },
        esc: {
            keyup: () => {
                if (this.flags.preQuiz) this.endPreQuiz()
            }
        }
    }
}

export default Hotkeys
