<template>
<div id="mini_search">
    <ul style="margin:0; padding: 0">
        <li class="search-bar" style="position:relative;">
            <input v-model="searchFld"
                id="minisearch"
                class="w-100"
                placeholder="Type a word"
                autocomplete="off"
                autofocus="off"
                @focus="showSearch = true"
            />
            <!-- show results of search -->
            <ul v-show="showSearch"
                class="autocomplete-ui w-100"
                :style="searchFld.length === 0 ? 'padding: 0; border: 0' : ''"
            >
                <li v-if="searchResults.length == 0 && searchFld.length > 0">
                    <div>
                        <span class="search-secondary" style="margin-left: 0">No results</span>
                    </div>
                </li>
                <li v-for="(items, indx) in searchResultsShowing"
                    :key="indx+'b'"
                >
                    <div class="d-flex justify-content-between">
                        <div>
                            <span lang="jp" class="search-primary" :class="items.card_type == 'v' ? 'c-purple' : 'c-red'">{{items.kanji}}</span>
                        </div>
                        <!-- buttons -->
                        <div>
                            <span
                                class="fa-stack clickable align-center"
                                @click="plusClick({word: items.kanji})"
                            >
                                <i class="fa-stack-2x fas fa-square c-purple"></i>
                                <i class="fa-stack-1x fas fa-plus"></i>
                            </span>
                        </div>
                    </div>
                    <div class="search-secondary align-left">
                            <span
                                v-if="items.pronunciation"
                                role="pronunciation"
                                lang="jp"
                                v-html="fixPronunciationHTML(items.pronunciation)"
                            ></span>
                            <span v-else>
                                <span
                                    role="pronunciation"
                                    lang="jp"
                                    v-html="items.pronunciation || items.reading[0]"
                                ></span>
                                <span
                                    lang="jp"
                                    v-if="items.reading.length > 1"
                                    style="opacity:.65"
                                >{{items.reading[1]}}</span>
                            </span>
                    </div>
                </li>
            </ul>
        </li>
    </ul>
</div>
</template>

<script>
import {
    isJapanese, isMixed, toHiragana, toKatakana
} from 'wanakana';
import C from '@/assets/common'

const {
    UseAPI, HandleRequestFail
} = C
const wanakana = {
    isJapanese, isMixed, toHiragana, toKatakana
}

export default {
    name: 'MiniSearch',
    created() {
        console.log('%cSearch created', window.ConsoleStyles.createdComponent, this)
        const vm = this
    },
    computed: {
        searchFldConverted() {
            const { searchFld } = this
            //  handle unexpected behavior that may happen with conversions
            const convertToJapanese = (val, func) => {
                const hasDoubleN = val.search('nn') !== -1
                let n = val
                if (hasDoubleN) n = n.replace('nn', 'ん')
                return func(n).replace(/[a-zA-Z]/g, '')
            }
            const obj = {
                eng: searchFld,
                hiragana: !wanakana.isJapanese(searchFld) ? convertToJapanese(searchFld, wanakana.toHiragana) : searchFld,
                katakana: !wanakana.isJapanese(searchFld) ? convertToJapanese(searchFld, wanakana.toKatakana) : searchFld
            }
            console.log('search field converted:', obj.eng, obj.hiragana, obj.katakana)

            return obj
        },
        searchResultsShowing() {
            const { searchResults } = this
            if (this.enabled === 'all') return searchResults
            if (this.enabled === 'v') return searchResults.filter((i) => i.card_type === 'v')
            return searchResults.filter((i) => i.card_type === 'k')
        }
    },
    watch: {
        searchFld(val) {
            console.log('detected change in searchFld', val, this.searchFldConverted)
            this.startSearch(val)
        },
    },
    props: {
        enabled: {
            required: false,
            default() {
                return 'all'
            }
        }
    },
    data() {
        return {
            searchFld: "",
            searchResults: [],
            searching: false,
            searchHovering: false,
            showSearch: false,
            searchTimestamp: 0,
            searchTimer: null,
        }
    },
    methods: {
        plusClick(obj) {
            this.searchFld = ""
            this.$emit('button-clicked', obj)
        },
        startSearch(val) {
            if (!val || !this.searchFldConverted.hiragana) {
                this.searchResults = []
                return true
            }
            //  if (!wanakana.isJapanese(this.searchFldConverted.hiragana)) return this.searchReq(this.searchFldConverted.eng, false)
            return this.searchReq(this.searchFldConverted.hiragana, true)
        },
        searchReq(str, jp = true) {
            const dat = new Date();
            const time = dat.getTime();
            const endpoint = jp ? "/get/lookup-jp" : "/get/lookup-en"
            if (this.searchTimer) {
                window.clearTimeout(this.searchTimer)
                console.log('timer cleared')
            }
            const req = () => {
                UseAPI(endpoint, { method: "POST", queryParameters: `word=${str}&timestamp=${time}` })
                .then((data) => {
                    this.searching = true
                    this.handleSearchReq(data)
                })
                .catch((data) => {
                    HandleRequestFail(data)
                    console.log('error log')
                    console.log(JSON.stringify(data))
                });
                this.searchTimer = null
            }
            this.searchTimer = window.setTimeout(req, 200)
            return req
        },
        handleSearchReq(dat) {
            // timestamp check
            if ('options' in dat === false
                || !('timestamp' in dat.options)
                || this.searchTimestamp > dat.options.timestamp
            ) return
            if ('word' in dat.options && !wanakana.isJapanese(dat.options.word)) return
            const addPronunciations = (arr) => {
                const { pronunciation } = arr.modules
                arr.items.forEach((item) => {
                    const found = pronunciation.items.findIndex(
                        (pro) => pro.word === item.kanji && wanakana.toHiragana(pro.reading) === item.reading
                    )
                    if (found !== -1) {
                        item.pronunciation = pronunciation.items[found].pronunciation
                    } else {
                        item.pronunciation = null
                    }
                })
            }
            this.searchTimestamp = dat.options.timestamp
            const final = []
            const searchedTerm = dat.options.word
            const words = dat.items
            addPronunciations(dat)
            words.forEach((item) => {
                'pos' in item
                    ? item.pos = item.pos.split(" ")
                    : item.pos = []
                // put in bar so you know where it deviated from exact search
                item.reading = item.reading.replace(searchedTerm, `${searchedTerm}|`).split('|')
                if (item.somethingelse <= 0) item.pos.push('rare')
                item.definition = item.definition.split(',')
                final.push(item)
            })
            this.searchResults = final
        },
        fixPronunciationHTML(pronunciationHTML) {
            return pronunciationHTML.replace(/class="nasal"/, `class="nasal" style="color: grey"`)
        },
    }
}
</script>

<style lang="sass" scoped>
.search-bar, .search-bar ul
    font-size: 1rem
    list-style-type: none
.search-bar
    position: relative
    .autocomplete-ui
        position: absolute
        left: 0
        top: 0
        padding: 0
        margin-top: 2rem
        padding-top: .5rem
        background-color: white
        border: 1px solid darkgrey
        border-top-color: transparent
        z-index: 99
        max-height: 30em
        overflow-y: auto
        span.fa-stack
            font-size: .85rem
            margin: 0 .5em
        li
            padding: 1px .25em
            margin-bottom: 1em
            &:hover
                background-color: #eee
    .search-primary
        font-weight: bold
        min-width: 300px
    .search-secondary
        color: grey
        font-size: .8rem
        *[lang="jp"]
            color: #434343!important
    .search-add
        position: absolute
        height: 100%
        right: .5em
        display: flex
        align-items: center
        display: none
    &:focus-within
        .search-add
            display: inline
    .badge
        margin-left: .5em
</style>
