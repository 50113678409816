import { UseAPI, HandleRequestFail } from "@/assets/common"

const MOCK_SUCCESS = (itemIds) => {
    alert('This better not be in production')
    return Promise.resolve({
        items: [],
        modules: {
            modified: {
                fieldCount: 0, affectedRows: itemIds.length, insertId: 0, info: `Rows matched: ${itemIds.length}  Changed: ${itemIds.length}  Warnings: 0`, serverStatus: 2, warningStatus: 0, changedRows: itemIds.length
            }
        },
        options: { items: [...itemIds] }
    })
}

export default ({
    arrayOfIds,
}, mock = false) => {
    // mock success
    if (mock === 'SUCCESS') {
        return MOCK_SUCCESS(arrayOfIds)
    }
    // mock failure
    // mock trash
    return UseAPI('/update/trash', {
        method: "POST",
        body: JSON.stringify({
            items: arrayOfIds
        })
    })
    .catch((err) => Promise.reject(HandleRequestFail(err)))
}
