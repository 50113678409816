import { UseAPI, HandleRequestFail } from "@/assets/common"

const requestFail = (dat) => {
    console.warn('Api error with getting example sentences')
    HandleRequestFail(dat)
    return false
}

export default (dataAsJSONString) => UseAPI('/get/examples', {
    method: "POST",
    body: dataAsJSONString,
    api: "corpus"
})
.catch((err) => Promise.reject(HandleRequestFail(err)))
