<template>
    <div class="modal-header">
        <h2 lang="ja" :class="wordClasses">
            <span v-if="!hasKanji">{{word}}</span>
            <span v-else-if="hasKanji"
                v-for="char in word"
                v-tippy
                :content="findKanji(char)"
                :key="char"
            >{{char}}</span>
        </h2>
        <!-- SRS post review controls -->
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: [
        'cardType',
        'kanjiInfo',
        'word',
    ],
    computed: {
        hasKanji() {
            return this.kanjiInfo ? !!this.kanjiInfo.length : false
        },
        wordClasses() {
            if (this.cardType === 'k') return 'c-red'
            return 'c-purp'
        }
    },
    methods: {
        findKanji(char) {
            const found = this.kanjiInfo.find((i) => i.kanji === char)
            if (!found) return 'kanji not found'
            const CLEAN_COMMAS = /,/g
            const CLEAN_TO = ', '
            const MAX_ENTRIES = 3
            return found.meaning
            .split(';')
            .slice(0, MAX_ENTRIES)
            .toString()
            .replace(CLEAN_COMMAS, CLEAN_TO)
        }
    }
}
</script>

<style>

</style>
