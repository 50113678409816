<template>
    <Modal
    :showing="showing"
    v-on:click-out="$emit('click-out')"
    position="stable"
    >
        <template v-slot:header>
            <div class="modal-header">
                <h2>Create Custom Card</h2>
                <span class="fa-stack clickable"
                @click="$emit('click-out')"
                style="font-size: 1em"
                >
                    <i class="fa-stack-2x fas fa-square"></i>
                    <i class="fas fa-times fa-stack-1x"></i>
                </span>
            </div>
        </template>
        <template v-slot:body>
            <div class="modal-body">
                <form autocomplete="off" @submit.prevent>
                    <div class="custom-word__field-group">
                        <Field class="custom-word__field" :key="theKey"
                        ref="startfield"
                        v-on:update="validateKanji($event)"
                        :badgeColor="hasKanjiErrors ? 'bg-danger' : 'bg-success'"
                        :placeholder="'Enter the card name (in kanji if relevant)'"
                        :isJp="true"
                        >
                            Card Name
                        </Field>
                        <Err :errors="errors.kanji" />
                    </div>
                    <div class="custom-word__field-group" :disabled="!form.kanji">
                        <Field class="custom-word__field" :key="theKey"
                        v-on:update="validateReading($event)"
                        :badgeColor="hasReadingErrors && 'bg-danger'"
                        :placeholder="'Enter the acceptable meanings (or leave blank)'"
                        :isJp="true"
                        >
                        Card Reading(s)
                        </Field>
                        <Err :errors="errors.reading" />
                    </div>
                    <div class="custom-word__field-group" :disabled="!form.kanji">
                        <Field class="custom-word__field" :key="theKey"
                        v-on:update="validateMeaning($event)"
                        :badgeColor="hasMeaningErrors && 'bg-danger'"
                        :placeholder="'Separate acceptable answers by ; or ,'">
                            Card Meaning(s)
                        </Field>
                        <Err :errors="errors.meaning" />
                    </div>
                    <div class="custom-word__field-group" :disabled="!form.kanji">
                        <Field class="custom-word__field" :key="theKey" :disabled="!form.kanji"
                        v-on:update="form.notes = $event"
                        :placeholder="'Notes will appear after answering a question. You can wrap text in * or ** to highlight specific parts of the content.'"
                        :isTextarea="true"
                        >
                            Notes
                        </Field>
                    </div>
                </form>
                <!-- alert -->
                <template v-if="!alert.hidden">
                    <Alert
                    :classes="alert.classes"
                    :key="alert.key"
                    :canClose="true"
                    v-on:destroy="alert.hidden = true"
                    >{{alert.msg}}</Alert>
                </template>
            </div>
        </template>
        <template v-slot:footer>
            <div class="modal-footer">
                <button class="btn w-100" :class="hasAnyErrors ? 'bg-secondary' : 'bg-primary'"
                @click="createCard" :disabled="hasAnyErrors">Create Card</button>
            </div>
        </template>
    </Modal>
</template>

<script>
import {
    isJapanese
} from 'wanakana'
import {
    Modal,
    Alert,
} from '@/components/Shared/index'
import Field from './Field.vue'
import Err from './Err.vue'
import {
    createCustomWord
} from '@/features/api/index'
import ApiPreloader from '@/assets/preloader'
import {
    randomInt,
} from '@/features/data/index'

export default {
    name: 'NewCustomWords',
    props: ['showing'],
    emits: ['click-out', 'add-success', 'alert'],
    components: {
        Modal,
        Field,
        Err,
        Alert,
    },
    watch: {
        showing(n) {
            this.alert.hidden = true
        }
    },
    methods: {
        validateReading(v) {
            const newErr = []
            const asArr = v.split(/[;,]/)
            asArr.forEach((anEntry) => {
                if (isJapanese(anEntry) === false) {
                    newErr.push('All readings should be Japanese')
                }
            })
            if (v === '' && this.form.meaning === '') {
                newErr.push('At least one meaning or reading is required')
            }
            this.errors.reading = Array.from(
                new Set(newErr)
            )
            this.form.reading = v
        },
        validateKanji(v) {
            const newErr = []
            if (v === '') {
                newErr.push('Entry cannot be blank')
            }
            this.errors.kanji = Array.from(
                new Set(newErr)
            )
            this.form.kanji = v
        },
        validateMeaning(v) {
            const newErr = []
            const asArr = v.split(/[;,]/)
            asArr.forEach((anEntry) => {
                if (anEntry === '' && asArr.length > 1) {
                    newErr.push('One of the meanings you entered could not be read')
                }
            })
            if (v === '' && this.form.reading === '') {
                newErr.push('At least one meaning or reading is required')
            }
            this.errors.meaning = Array.from(
                new Set(newErr)
            )
            this.form.meaning = v
        },
        formReset() {
            this.form.kanji = ''
            this.form.reading = ''
            this.form.meaning = ''
            this.form.notes = ''
            this.validateReading('')
            this.validateKanji('')
            this.validateMeaning('')
        },
        finalValidate() {
            let passed = true
            const keys = Object.keys(this.errors)
            keys.forEach((aKey) => {
                if (this.errors[aKey].length > 0) passed = false
            })
            return passed
        },
        createCard() {
            const {
                kanji: card, reading: readings, meaning: synonyms, notes
            } = this.form
            if (!this.finalValidate()) return
            createCustomWord({
                card,
                q_state: 0,
                readings,
                synonyms,
                notes
            })
            .then(() => {
                this.$emit('add-success')
                this.formReset()
                this.theKey += 1
                this.alert = ({
                    msg: 'Word successfully added',
                    classes: 'alert-success',
                    hidden: false,
                    key: randomInt(100),
                })
                // also clear preload data
                ApiPreloader.clearApiKey('/lessons')
            })
            .catch((dat) => {
                // generic error
                this.alert = {
                    msg: `Error adding custom card. Check if this card already exists on your account.`,
                    classes: 'alert-danger',
                    hidden: false,
                    key: randomInt(100),
                }
            })
        },
        focusInitial() {
            const FIELD = 'startfield'
            this.$nextTick(() => {
                const node = this.$refs[FIELD]?.$refs?.fieldinput
                if (node) node.focus()
            })
        },
    },
    data() {
        return {
            errors: {
                kanji: [],
                meaning: [],
                reading: [],
            },
            form: {
                kanji: '',
                reading: '',
                meaning: '',
                notes: '',
            },
            alert: {
                classes: 'alert-danger',
                msg: 'Test',
                key: randomInt(),
                hidden: true,
            },
            theKey: 0, // force a reinit of fields https://michaelnthiessen.com/force-re-render
        }
    },
    computed: {
        hasKanjiErrors() {
            return !!this.errors.kanji.length
        },
        hasMeaningErrors() {
            return !!this.errors.meaning.length
        },
        hasReadingErrors() {
            return !!this.errors.reading.length
        },
        hasAnyErrors() {
            const errs = this.hasKanjiErrors || this.hasReadingErrors || this.hasMeaningErrors
            const atLeastOneQ = this.form.reading || this.form.meaning
            return errs || !atLeastOneQ
        },
    },
    mounted() {
        this.formReset()
        this.focusInitial()
    }
}
</script>

<style lang="scss" scoped>
.custom-word {
    &__field-group {
        margin-bottom: 1rem;
        &[disabled] {
            opacity: .5;
            pointer-events: none;
        }
    }
    &__field {
        font-size: 1.25rem;
        line-height: 1.75;
        width: 100%;
    }
}
</style>
