<template>
  <div class="i-group">
    <div>
      <span class="form-field badge" :class="badgeColor || 'bg-secondary'"
        ><slot></slot
      ></span>
    </div>
    <template v-if="!isTextarea">
      <input
        ref="fieldinput"
        :placeholder="placeholder || 'enter a value'"
        v-model="value"
        @blur="finalizeField"
      />
    </template>
    <template v-else>
      <textarea
        class="w-100"
        ref="fieldinput"
        :placeholder="placeholder || 'enter a value'"
        v-model="value"
        @blur="finalizeField"
      ></textarea>
    </template>
  </div>
</template>

<script>
import { isMixed } from "wanakana";
import { convertToJp } from "@/features/japanese/index";

export default {
    name: "CustomWordField",
    props: ["placeholder", "initialValue", "badgeColor", "isJp", "isTextarea"],
    emits: ["update"],
    data() {
        return {
            value: "",
        };
    },
    mounted() {
        console.log("field got", this.initialValue);
        this.value = this.initialValue || "";
    },
    methods: {
        finalizeField() {
            if (this.isJp) this.value = convertToJp(this.value, true);
        },
    },
    watch: {
        value(n) {
            if (this.isJp) {
                // prevent rerender loop
                const converted = convertToJp(n, false);
                const noChangeAfterConvert = n === converted;
                const notBlank = n !== "";
                // make sure it triggers for compositionend changes (ex: type 感じ using ime)
                const isNotMixed = isMixed(n) === false;
                if (noChangeAfterConvert && notBlank && !isNotMixed) return;
                // modify the value
                this.value = converted;
            }
            this.$emit("update", this.value);
        },
    },
};
</script>

<style lang="scss" scoped>
.form-field {
  min-width: 8.5em;
}
textarea {
  line-height: 1.3;
}
</style>
