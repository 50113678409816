<template>
    <Modal :showing="showing" v-on:click-out="$emit('click-out')" position="stable">
        <template v-slot:header>
            <div class="modal-header">
                <h2>Add Kanji From Text</h2>
                <span class="fa-stack clickable" @click="$emit('click-out')" style="font-size: 1em">
                    <i class="fa-stack-2x fas fa-square"></i>
                    <i class="fas fa-times fa-stack-1x"></i>
                </span>
            </div>
        </template>
        <template v-slot:body>
            <div class="modal-body">
                <p>A simple tool to extract kanji from any given plain text.</p>
                <div class="add-kanji__shortcut --sect">
                    <ClickableBadge class="bg-secondary" :hoveringClasses="'bg-info --hovering'" v-on:clicked="grabLessons">
                        Grab from lessons
                    </ClickableBadge>
                </div>
                <div class="--sect">
                    <Field class="custom-word__field" ref="startfield" v-on:update="extract"
                        :badgeColor="1 == 1 ? 'bg-danger' : 'bg-success'" :placeholder="'Enter text to extract kanji from'"
                        :isJp="true" :isTextarea="true">
                        Text
                    </Field>
                </div>
                <template v-if="extractedData.ordering.length > 0">
                    <h2>Kanji Extracted</h2>
                    <div>
                        <ClickableBadge class="bg-secondary" :hoveringClasses="'bg-info --hovering'"
                            v-on:clicked="setAll()">
                            Select all
                        </ClickableBadge>
                        <ClickableBadge class="bg-secondary" :hoveringClasses="'bg-info --hovering'"
                            v-on:clicked="setNone()">
                            Select none
                        </ClickableBadge>
                    </div>
                    <div class="add-kanji__badge-area">
                        <ClickableBadge v-for="(aKey, i) in extractedData.ordering" :key="i" class="clickable"
                        activeClasses="bg-success"
                        inactiveClasses="bg-secondary"
                        :active="extractedData.get(aKey).selected"
                        v-on:clicked="
                                extractedData.get(aKey).selected =
                                !extractedData.get(aKey).selected
                                ">
                            {{ extractedData.get(aKey).kanji }}
                        </ClickableBadge>
                    </div>
                    <Alert v-if="!alert.hidden" :classes="alert.classes" :key="alert.key" :canClose="true"
                        v-on:destroy="alert.hidden = false">{{ alert.msg }}</Alert>
                </template>
            </div>
        </template>
        <template v-slot:footer>
            <div class="modal-footer">
                <!-- TODO: add disable -->
                <button class="btn bg-primary" @click="addKanji">Add Kanji</button>
            </div>
        </template>
    </Modal>
</template>
<script>
import { extractKanji } from "@/features/japanese/index";
import { Modal, Alert } from "@/components/Shared/index";
import {
    Field,
    /* Err, */
} from "@/components/Lessons/CustomWord/index";
import { addKanji } from "@/features/api/index";
import { ClickableBadge } from "./index";
import { MapPolyfill, randomInt } from "@/features/data/index";

export default {
    name: "AddKanji",
    props: ["showing", "lessons"],
    components: {
        Field,
        Modal,
        ClickableBadge,
        Alert,
    },
    data() {
        return {
            text: "",
            // extracted kanji
            extractedData: new MapPolyfill(),
            alert: {
                classes: "alert-danger",
                msg: "Test",
                key: randomInt(),
                hidden: true,
            },
        };
    },
    watch: {
        showing(n) {
            this.alert.hidden = true;
        },
    },
    methods: {
        extract(str) {
            const data = extractKanji(str);
            const newData = data.map(this.extractedKanjiFormat);
            const KEY = "kanji";
            newData.forEach((value) => {
                const keyVal = value[KEY];
                this.extractedData.append(value, keyVal, {
                    write: false,
                    ovewrite: false,
                    reorder: true,
                });
                this.$set(this.extractedData.data, keyVal, value);
            });
        },
        extractedKanjiFormat(str) {
            return {
                kanji: str,
                selected: false,
            };
        },
        clearExtracted() {
            this.extracted = new Map();
        },
        grabLessons() {
            const lessonsAsString = this.lessons.join("");
            this.extract(lessonsAsString);
        },
        setAll() {
            this.extractedData.ordering.forEach((aKey) => {
                this.extractedData.data[aKey].selected = true;
            });
        },
        setNone() {
            this.extractedData.ordering.forEach((aKey) => {
                this.extractedData.data[aKey].selected = false;
            });
        },
        addKanji() {
            const itemsToAdd = this.extractedData
                .filter((anItem) => anItem.selected)
                .map((anItem) => ({
                    card: anItem.kanji,
                    q_state: 0,
                    readings: anItem.kanji,
                }));
            console.log(itemsToAdd, "items to add");
            return addKanji(itemsToAdd)
                .then(({ modules }) => {
                    const def = {
                        msg: `There was an issue with the request`,
                        classes: "alert-danger",
                    };
                    if (!modules) {
                        return def;
                    }
                    if (modules.created) {
                        const tackOn = modules.failure ? " The rest may be duplicates" : "";
                        return {
                            msg: `Successfully added ${modules.created.length} new kanji.${tackOn}`,
                            classes: "alert-success",
                        };
                    }
                    if (modules.failure) {
                        return {
                            msg: `All items were duplicates!`,
                            classes: "alert-warning",
                        };
                    }
                    return def;
                })
                .then(({ classes, msg }) => {
                    console.log("testing", classes, msg);
                    this.alert.classes = classes;
                    this.alert.msg = msg;
                    this.alert.hidden = false;
                    this.alert.key = randomInt(100);
                    this.$emit("add-success");
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.--sect {
    margin-bottom: 1rem;
}

.add-kanji {
    &__shortcut {
        font-size: 1.25rem;
    }

    &__badge-area {
        font-size: 1.5rem;
        font-weight: normal;
    }
}
</style>
