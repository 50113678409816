<template>
    <div>
        <div class="col-12 pad-big book-card" @click.prevent="cardClicked(items)" @mouseenter="cardMousedOver(items)">
            <h2 class="resizable">
                <span v-if="items.word.length < 6" lang="ja" :class="items.card_type == 'v' ? 'c-purple' : 'c-red'">{{
                    items.word }}</span>
                <span v-else lang="ja" :class="items.card_type == 'v' ? 'c-purple' : 'c-red'" v-tippy :content="items.word">
                    {{ items.word.substr(0, 5) + '…' }}
                </span>
                <span v-if="items.trashed"><i class="fas fa-trash"></i></span>
            </h2>
            <div class="pad-big d-flex card-button-holder">
                <!--selected options-->
                <div :style="items.selected ? 'opacity:1' : 'opacity:0'" class="fa-stack fa">
                    <!--use :style so that cards keep their shape-->
                    <i class="fa fa-circle fa-stack-2x icon-background c-green"></i>
                    <i :class="`fa fa-check fa-stack-1x c-white`"></i>
                </div>
                <div v-if="items.selected" class="fa-stack fa" @click.stop.prevent="openModal(items)">
                    <i class="fa fa-square fa-stack-2x icon-background c-purple"></i>
                    <i :class="`fa fa-edit fa-stack-1x c-white clickable anim-shimmer`" v-tippy content="Edit"></i>
                </div>
                <div v-if="items.selected" class="fa-stack" @click.stop.prevent="copyToClipboard(items.word)">
                    <i class="fa fa-square fa-stack-2x icon-background c-purple"></i>
                    <i :class="`fas fa-copy fa-stack-1x c-white clickable anim-shimmer`" v-tippy content="Copy text"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import C from '@/assets/common'

const { SendUserAlert } = C

export default {
    name: 'Card',
    props: ['items', 'mdown'],
    methods: {
        cardClicked(item) {
            this.$emit('card-selected', item)
        },
        cardMousedOver(item) {
            if (this.mdown) this.$emit('card-selected', item)
        },
        openModal(item) {
            this.$emit('open-modal', { items: item, closable: true })
        },
        copyToClipboard(val) {
            const temp = document.createElement('input')
            document.body.append(temp)
            temp.value = val
            temp.select()
            document.execCommand("copy")
            temp.remove()
            SendUserAlert('Copied', 'alert-info')
            return true
        },
    }
}
</script>

<style lang="sass" scoped>
h2
    @media (min-width: 1024px)
        padding-left: .25em
    border-bottom: 2px dashed #eee
.card-button-holder
    //border-top: 2px dashed #eee
    justify-content: center
    vertical-align: center
body
    &.dark
        .c-purple
</style>
