import { UseAPI, HandleRequestFail } from "@/assets/common"

export default ({
    card,
    // eslint-disable-next-line camelcase
    q_state,
    readings,
    synonyms,
    notes
}) => UseAPI('/create/add-custom', {
    method: "PUT",
    body: JSON.stringify(
        {
            item: {
                card, q_state, readings, synonyms, notes
            }
        }
    )
})
.catch((err) => Promise.reject(HandleRequestFail(err)))
