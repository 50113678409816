<template>
    <div id="lessonsList" @mousedown="() => flags.mousedown = true" @mouseleave="flags.mousedown = false"
        @mouseup="flags.mousedown = false" style="min-height: 900px" v-hotkey.stop="Keymap">
        <Toasts v-on:hide-toasts="1 == 1" :toasts="toasts" v-on:close-all="toasts = []"
            v-on:disable-help="[opts.tutorial = false, toasts = []]" />
        <template v-if="flags.loaded">
            <AddKanji :showing="modals.addKanji" :lessons="wordsToString" v-on:alert="$emit('alert', $event)"
                v-on:click-out="modals.addKanji = false" v-on:add-success="[swapWordBank('lessons'), refreshLessons()]" />
            <NewCustomWord v-if="!flags.loading" :showing="modals.newCustomWord" v-on:alert="$emit('alert', $event)"
                v-on:click-out="modals.newCustomWord = false"
                v-on:add-success="[swapWordBank('lessons'), refreshLessons()]" />
            <LessonsEditor v-if="!flags.loading" :showingModal="modals.editor" :mode="'lessons'" :item="modal"
                :words="words" :kanjiInfo="kanjiInfo" :flags="flags" :pronunciation="pronunciation" :reference="reference"
                :examples="examples" examples-enabled="1" :word="modal.word"
                :url="{ path: '/review', query: { ids: flags.quizQueue.join(',') } }" :inQuizMode="flags.preQuiz"
                :canNavigate="flags.quizQueue.length > 1" :cardIsVocab="modal.card_type !== 'k'"
                :hasCorpusExamples="examplesToShow && examplesToShow.length > 0" :examplesToShow="examplesToShow"
                v-on:trash="startTrash($event)" v-on:delete="startDelete($event)" v-on:click-out="closeEditModal()"
                v-on:close="closeEditModal()" v-on:cancel="closeEditModal()" v-on:left="changeQuizIndex(false)"
                v-on:right="changeQuizIndex(true)">
                <template v-slot:header-top>
                    <!-- hotkeys -->
                    <!-- header -->
                    <HeaderTop :cardType="modal.card_type" :kanjiInfo="kanjiInfo" :word="modal.word">
                        <TopControls :canNavigate="flags.quizQueue.length > 1" v-on:close="closeEditModal()"
                            v-on:left="changeQuizIndex(false)" v-on:right="changeQuizIndex(true)">
                        </TopControls>
                    </HeaderTop>
                </template>
            </LessonsEditor>
        </template>
        <!--err-->
        <div v-if="flags.error" class="alert alert-warning alert-dismissible fade show" role="alert">
            <h4 class="alert-heading">An error occured</h4>
            <div>error message: <strong>{{ `${flags.err_msg !== '' ? flags.err_msg : 'no specific message reported'}`
            }}</strong></div>
            <div v-if="flags.err_msg = 'not logged in'"><a href="/accounts/login/">click to login</a></div>
        </div>
        <Spinner v-if="flags.loading" :badge="'bg-success'" :message="'Loading'" />
        <!-- app -->
        <div v-else class="row">
            <div v-if="!flags.loaded" class="col-12 col-md-9 order-2 order-md-1"><!--space filler--></div>
            <div class="col-12 col-md-9 order-2 order-md-1">
                <!--search-->
                <LessonsSearch class="book-card pad-big marg-big" v-on:req-delete="startDelete($event)"
                    v-on:refresh-list="[swapWordBank('lessons'), refreshLessons()]">
                    <template v-slot:custom-slot="slotProps">
                        <Dropdown :forceStatus="dropdown.searchBar.forceStatus || false" @click.native="slotProps.close"
                            class="--light" style="box-shadow: 0px 0px black">
                            <template v-slot:title-full>
                                <div class="align-center w-100">
                                    <i class="fa fa-plus"></i>
                                </div>
                            </template>
                            <template v-slot:body>
                                <div @click="[closeDropdownSearchBar(), modals.newCustomWord = true]">New custom card</div>
                                <div @click="[closeDropdownSearchBar(), modals.addKanji = true]">Add kanji from text</div>
                            </template>
                        </Dropdown>
                    </template>
                </LessonsSearch>
                <!--start-->
                <div id="main" class="row" @selectstart.prevent>
                    <div class="wrap col-12" style="padding-bottom: 1em; align-self: flex-end;">
                        <!--intro card-->
                        <Intro class="pad-big book-card" :flags="flags" :words="words" v-on:next-page="pagination('right')"
                            v-on:prev-page="pagination('left')">
                            <span slot="heading">
                                <span v-if="flags.controls === 'lessons' && words.length > 0">These are items you've added
                                    from books</span>
                                <span v-else-if="flags.controls === 'lessons'">You've got no items to learn</span>
                                <span v-else-if="flags.controls === 'recent'">These are recently reviewed items</span>
                            </span>
                            <div v-if="flags.controls === 'lessons' && words.length > 0" slot="message">
                                <b>Select a few and <span v-tippy
                                        content="Click a few words and then click the checkmark on the right">quiz</span>
                                    yourself on them</b>. Get them right and they'll appear periodically as <b>reviews</b>.
                            </div>
                            <div v-else-if="flags.controls === 'lessons'" slot="message">
                                <router-link to="/list" style="text-decoration:underline" v-tippy
                                    content="You can also click 'learn' on the navigation to access vocabulary lists">Generate
                                    a vocabulary list from a book</router-link> and add some words. They'll appear here,
                                where you can add them to your <span style="text-decoration:underline" v-tippy
                                    content="Once you've quizzed yourself on an item here, they'll show up periodically for review">review
                                    queue</span>.
                            </div>
                            <div v-else-if="flags.controls === 'recent'" slot="message">You can delete or edit them here.
                            </div>
                        </Intro>
                    </div>
                    <div v-if="!flags.loaded && !flags.error" class="loadable w-100" style="min-height: 150px">
                    </div>
                    <Card class="wrap col-12 col-sm-6 col-md-4 col-xl-3 margin-big" v-for="(items, indx) in wordsToShow"
                        style="padding-bottom:.5em; align-self: flex-end;" :items="items" :mdown="flags.mousedown"
                        :key="indx" v-on:card-selected="selectAnItem($event)" v-on:open-modal="handleModalEmit($event)" />
                    <!--EOR-->
                </div>
            </div>
            <!--side-->
            <div id="side" class="col-12 col-md-3 row order-1 order-md-2">
                <!--content-->
                <div class="col-12 module navigation">
                    <h2>Navigation</h2>
                    <div class="w-100 badge clickable" :class="flags.controls === 'lessons' ? 'bg-success' : 'bg-secondary'"
                        @click="swapWordBank('lessons')"><span>Lessons</span></div>
                    <div class="w-100 badge clickable" :class="flags.controls === 'recent' ? 'bg-success' : 'bg-secondary'"
                        @click="swapWordBank('recent')"><span>Recent</span></div>
                </div>
                <div class="col-12 module">
                    <h2 v-text="words.some((i) => i.selected == true) ? 'Actions' : 'Select an item'"></h2>
                    <div class="w-100"><span class="badge" :class="cNumSelected[0] > 0 ? 'bg-purple' : 'bg-secondary'"
                            style="width:100%">{{ cNumSelected }}</span></div>
                    <div id="lessons-actions" class="d-flex marg-big" style="justify-content:space-evenly;">
                        <div v-if="flags.controls === 'lessons'">
                            <BLabel label="Quiz" v-tippy content="Start Quiz"
                                :colors="{ outer: 'c-purple', text: 'bg-purple' }"
                                :activated="cNumSelected[0] > 0 ? true : false" v-on:click="startReview(getSelected())" />
                        </div>
                        <div>
                            <BLabel label="Trash" v-tippy content="Trash items"
                                :colors="{ outer: 'c-purple', text: 'bg-purple' }" icon="fa-trash"
                                :activated="cNumSelected[0] > 0 ? true : false" v-on:click="handleTrash()" />
                        </div>
                        <div>
                            <BLabel label="Delete" v-tippy content="Delete items" icon="fa-times"
                                :colors="{ outer: 'c-purple', text: 'bg-purple' }"
                                :activated="cNumSelected[0] > 0 ? true : false" v-on:click="handleDelete()" />
                        </div>
                    </div>
                </div>
                <div class="d-none d-md-block col-12 module">
                    <h2>Schedule</h2>
                    <div class="col-12 col-lg-10 mx-auto">
                        <div class="row user-schedule marg-small" v-for="(items, indx) in schedule" :key="indx">
                            <div class="col-6 align-right ml-left" v-tippy :content="items.description">
                                <h2>{{ items.label }}</h2>
                            </div>
                            <h2 class="col" style="align-self:center;">{{ items.val }}</h2>
                            <!--EOR-->
                        </div>
                    </div>
                </div>
            </div>
            <!--EOR-->
        </div>
        <!--EOR-->
    </div>
</template>

<script>
import C from '@/assets/common'
import { SS } from '@/assets/constants'
import getSavedOptions from '@/assets/js/getSavedOptions'
import TOAST_CONFIG from '@/assets/js/toastsConfig/lessons'
import {
    getScreenBreakpoint,
} from '@/features/responsive/index'
// COMPONENTS
import {
    Card,
    /* LessonsSearch, */
    Intro,
    /* LessonsEditor, */
    /* NewCustomWord, */
    /* AddKanji, */
    /* HeaderTop, */
    /* TopControls, */
} from './index'
import HeaderTop from './Editor/Lessons/HeaderTop.vue'
import TopControls from './Editor/Lessons/TopControls.vue'
import Keymap from './hotkeys'
import Spinner from '../Spinner.vue'
import {
    ButtonWithLabel as BLabel,
    Toasts,
    Dropdown,
} from '@/components/Shared/index'
import {
    getRecentWords,
    getExampleSentences,
    getLessons,
    deleteWord,
    trashWord,
    createUserSettings,
} from '@/features/api/index'
import {
    exampleSentenceHTML
} from '@/features/render/index'
import {
    changePage
} from '@/features/pagination/index'
import AddKanji from './AddKanji/AddKanji.vue'
import LessonsEditor from './Editor/Lessons/LessonsEditor.vue'
import NewCustomWord from './CustomWord/NewCustomWord.vue'
import LessonsSearch from './Search.vue'

const {
    UseAPI,
    HandleRequestFail,
    SendUserAlert,
    getUserSettings,
} = C

export default {
    name: 'Lessons',
    components: {
        Card,
        LessonsSearch,
        Spinner,
        BLabel,
        Intro,
        Toasts,
        // editor
        LessonsEditor,
        HeaderTop,
        TopControls,
        // custom modals
        NewCustomWord,
        Dropdown,
        AddKanji,
    },
    watch: {
        opts: {
            handler(arr) {
                this.saveSettings()
            },
            deep: true
        }
    },
    data() {
        return {
            //  searchFld: '',
            modals: {
                editor: false,
                custom: false,
                newCustomWord: false,
                addKanji: false,
            },
            dropdown: {
                searchBar: {
                    forceStatus: false,
                },
            },
            toasts: [],
            possibleToasts: null,
            opts: {
                tutorial: true,
            },
            words: [],
            wordsBackup: {
                words: [], pronunciation: [], reference: [], kanjiInfo: []
            }, //   for holding swapped recent and lessons arrays
            searchResults: [],
            kanjiInfo: [],
            pronunciation: [],
            reference: [],
            flags: {
                debug: true,
                optionsInitiated: false, // has done initial options setup in created()
                loaded: false, //   has loaded a list
                loading: true, //   is loading a large ajax request
                itemSelected: 123,
                error: false,
                err_msg: '',
                mousedown: false,
                preQuiz: false,
                quizQueue: [],
                quizIndex: 0,
                selectedReference: null,
                editingNotes: false,
                editingNoteText: "",
                /* pagination */
                pagination: 0,
                paginationLength: 12,
                controls: 'lessons',
                exampleSentenceTimer: null,
            },
            modal: {
                id: 0, card: 0, readings: [], source: 0, synonyms: '', entries: []
            },
            schedule: {
                now: { val: 0, label: 'now', description: 'reviews due now' },
                '8hr': { val: 0, label: 'soon', description: 'reviews due within 8 hours' },
                '23hr': { val: 0, label: 'today', description: 'reviews due within 23 hours' },
                lessons: { val: 0, label: 'lessons', description: 'lessons available' },
                learned: { val: 0, label: 'learned today', description: 'items learned today' },
            },
            examples: []
        }
    },
    methods: {
        init(dat) {
            console.log('initing', dat)
            const items = dat.items
            function handleErr(vm, data) {
                vm.report('error')
                vm.report(data.options)
                vm.flags.error = true
                if (typeof data.e === 'string') {
                    vm.flags.err_msg = data.e
                }
            }
            // main
            // error check
            if (dat.items.length <= 0 && dat.e === 'not logged in') {
                handleErr(this, dat)
                return false
            }
            for (let i = 0; i < items.length; i += 1) {
                items[i].selected = false
                items[i].trashed = false
                this.words.push(items[i])
            }
            if (dat.items.length > 0) {
                this.kanjiInfo = this.getKanjiInfo(dat)
                this.pronunciation = this.getPronunciationInfo(dat)
                this.reference = this.getReferenceInfo(dat)
            }
            this.setScheduleInfo(dat)
            this.flags.loaded = true
            this.flags.loading = false
            return true
        },
        getKanjiInfo(dat) {
            if (!dat.modules || !dat.modules.kanjiInfo) {
                console.log('failed to load kanji info');
                return [];
            }
            return dat.modules.kanjiInfo.items
        },
        getPronunciationInfo(dat) {
            if (!dat.modules || !dat.modules.pronunciation) {
                console.log('failed to load pronunciation info');
                return [];
            }
            return dat.modules.pronunciation.items
        },
        getReferenceInfo(dat) {
            if (!dat.modules || !dat.modules.reference) {
                console.log('failed to load reference words info');
                return [];
            }
            return dat.modules.reference.items
        },
        setScheduleInfo(dat) {
            const { modules } = dat
            if (!modules || !dat.modules.schedule) {
                console.log('Failed to load schedule info')
                return []
            }

            const keys = Object.keys(modules.schedule)
            keys.forEach((i) => {
                this.schedule[i].val = modules.schedule[i]
            })
            return modules.schedule
        },
        getRecentWords,
        resetData() {
            this.words = []
            this.searchResults = []
            this.kanjiInfo = []
            this.pronunciation = []
            this.reference = []
        },
        commitRecentWords(dat) {
            this.resetData()
            this.init(dat)
        },
        async swapWordBank(to = null) {
            if (!to || to === this.flags.controls) return false
            this.flags.controls = to
            this.flags.loading = true

            const temp = {
                words: this.words,
                pronunciation: this.pronunciation,
                reference: this.reference,
                kanjiInfo: this.kanjiInfo,
            }

            if (to === 'recent' && this.wordsBackup.words.length === 0) {
                // do a full INIT
                this.getRecentWords()
                    .then((result) => this.commitRecentWords(result))
            } else {
                this.words = this.wordsBackup.words
                this.reference = this.wordsBackup.reference
                this.kanjiInfo = this.wordsBackup.kanjiInfo
                this.pronunciation = this.wordsBackup.pronunciation
            }

            // commit to swap
            this.wordsBackup = temp
            this.flags.pagination = 0
            this.flags.loading = false
            return true
        },
        async getExampleSentences(arr) {
            let words = arr.map((i) => i.word).slice(0, 999)
            // this is such fucking lazy code dude...
            const ukWords = []
            arr.forEach((i) => {
                i.entries.forEach((o) => {
                    const usuallyKana = o.subentries.find((p) => {
                        if ('pos' in p && p.pos.search(/uk/) !== -1) return true
                        return false
                    })
                    if (usuallyKana) ukWords.push(o.reading)
                })
            })
            words = [...words, ...ukWords]
            if (words.length === 0) return false
            const data = { words: words.toString(), limit: 1 }
            getExampleSentences(JSON.stringify(data))
                .then((dat) => {
                    if (dat && dat.items.length > 0) {
                        dat.items.forEach((i) => {
                            i.scoreChange = 1
                            i.alreadyScored = false
                        })
                        this.examples.push(...exampleSentenceHTML(dat.items))
                        this.examples.sort((a, b) => a.paragraph.length - b.paragraph.length)
                    }
                    return dat
                })
                .catch(() => {
                    console.warn('error setting example sentences')
                })
            return true
        },
        onResize() {
            const currentBreakpoint = getScreenBreakpoint()
            let val = 12
            if (currentBreakpoint === 'SM') val = 4
            else if (currentBreakpoint === 'MD') val = 6
            else if (currentBreakpoint === 'LG') val = 9
            this.flags.paginationLength = val
        },
        exampleSentenceHTML,
        getSelected() {
            /* get selected ids for a query */
            const ids = []
            this.words.forEach((i) => {
                if (i.selected) ids.push(i.id)
            })
            return ids
        },
        selectAnItem(item) {
            item.selected ? item.selected = false : item.selected = true
            let flagState = false
            for (let i = 0; i < this.words.length; i += 1) {
                if (this.words[i].selected) {
                    flagState = true
                    break
                }
            }
            this.flags.itemSelected = flagState
        },
        pagination(val) {
            this.flags.pagination = changePage({
                direction: val,
                current: this.flags.pagination,
                step: this.flags.paginationLength,
            }, this.words.length)
        },
        refreshLessons() {
            return getLessons()
                .catch(() => {
                    SendUserAlert('Issue refreshing current lessons', 'alert-danger')
                })
                .then(this.commitRecentWords)
        },
        handleTrash() {
            if (this.cNumSelected[0] > 0 && confirm('Trash selected items?')) this.startTrash(this.getSelected())
        },
        startReview(ids) {
            if (!Array.isArray(ids) || ids.length < 1) {
                SendUserAlert('No items selected', 'alert-warning')
                return false
            }
            this.flags.preQuiz = true
            this.flags.quizQueue = this.cleanIds(ids)
            this.flags.quizIndex = 0
            this.setModal(this.words.find((i) => i.id === this.flags.quizQueue[this.flags.quizIndex]))
            return true
        },
        cleanIds(ids) {
            // get rid of any ids for an action if its been trashed (or otherwise removed)
            const idsToRemove = []
            const vm = this

            ids.forEach((id) => {
                const equivalentItem = vm.words.find((card) => card.id === id)
                if (equivalentItem && equivalentItem.trashed) { idsToRemove.push(id) }
            })
            return ids.filter((i) => !idsToRemove.includes(i))
        },
        startTrash(ids) {
            if (!Array.isArray(ids) || ids.length < 1) {
                SendUserAlert('No items selected', 'alert-warning')
                return false
            }
            const vm = this
            trashWord({
                arrayOfIds: ids
            })
                .then((dat) => {
                    //  const compare = dat.ret.success.map((i) => i.id)
                    const run = this.flags.controls === 'lessons'
                        ? this.refreshLessons()
                        : this.getRecentWords()
                            .then((result) => this.commitRecentWords(result))
                    run.then(() => {
                        if (
                            this.words.length > 0
                            && this.words.slice(this.flags.pagination, this.flags.pagination + this.flags.paginationLength).length === 0
                        ) this.flags.pagination -= this.flags.paginationLength
                        this.closeEditModal()
                    })
                })
                .catch((dat) => {
                    SendUserAlert('there was an error deleting the words\ncheck console for error logs', 'alert-danger')
                    HandleRequestFail(dat)
                    console.warn('failed to delete; error log')
                    console.warn(JSON.stringify(dat))
                })

            return ids
        },
        handleDelete() {
            if (this.cNumSelected[0] > 0 && confirm('Delete selected items?')) this.startDelete(this.getSelected())
        },
        startDelete(ids) {
            if (!Array.isArray(ids) || ids.length < 1) {
                SendUserAlert('No items selected', 'alert-warning')
                return false
            }
            return deleteWord({
                arrayOfIds: ids
            })
                .then((dat) => {
                    const run = this.flags.controls === 'lessons'
                        ? this.refreshLessons()
                        : this.getRecentWords()
                            .then((result) => this.commitRecentWords(result))
                    run.then(() => {
                        if (
                            this.words.length > 0
                            && this.words.slice(this.flags.pagination, this.flags.pagination + this.flags.paginationLength).length === 0
                        ) this.flags.pagination -= this.flags.paginationLength
                        this.closeEditModal()
                    })
                })
                .catch((err) => {
                    SendUserAlert('there was an error deleting the words\ncheck console for error logs', 'alert-danger')
                    console.warn('failed to delete; error log')
                    console.warn(JSON.stringify(err))
                })
        },
        closeEditModal() {
            this.endPreQuiz()
            this.modals.editor = false
        },
        setModal(item) {
            this.modal = item
            this.modals.editor = true
        },
        changeQuizIndex(forward) {
            const newPage = changePage({
                direction: forward ? 'right' : 'left',
                current: this.flags.quizIndex,
                step: 1
            }, this.flags.quizQueue.length)
            this.flags.quizIndex = newPage
            this.modal = this.words.find((i) => i.id === this.flags.quizQueue[this.flags.quizIndex])
            //  reset reference words
            this.flags.selectedReference = null
        },
        endPreQuiz() {
            if (!this.flags.preQuiz) return
            const act = () => {
                this.flags.preQuiz = false
            }
            this.editor
            window.setTimeout(act, 500)
        },
        //  handle emits
        handleModalEmit(e) {
            const { items } = e
            this.setModal(items)
        },
        saveSettings() {
            //  send to server
            const body = JSON.stringify({
                context: 'lessons',
                val: JSON.stringify(this.opts)
            })
            if (this.flags.optionsInitiated) {
                createUserSettings(body)
                    .then(() => {
                        SendUserAlert('Settings saved to server', 'alert-success')
                        //  remove cookie and refresh, so that it doesn't hold up future page loads
                        SS.remove(SS.USER_SETTINGS_COOKIE)
                        getUserSettings()
                    })
                    .catch((result) => {
                        SendUserAlert('Error saving user settings to server', 'alert-danger')
                        console.warn('Error saving user settings to server', result)
                    })
            }
        },
        // dropdown
        closeDropdownSearchBar() {
            this.dropdown.searchBar.forceStatus = true
            this.$nextTick(() => {
                this.dropdown.searchBar.forceStatus = false
            })
        }
    },
    computed: {
        Keymap,
        examplesToShow() {
            const { examples, modal } = this
            return examples.filter((i) => i.val === modal.word || modal.entries.map((o) => o.reading).includes(i.val))
        },
        wordsToShow() {
            return this.words.slice(this.flags.pagination, this.flags.pagination + this.flags.paginationLength)
        },
        cNumSelected() {
            const { flags } = this
            const count = this.words.filter((i) => i.selected === true).length

            const funkyWunk = () => {
                console.log('gonna get shit')
                const selected = this.getSelected()
                if (selected.length === 0) return false
                let words = this.words.filter((i) => selected.includes(i.id) && i.card_type === 'v')
                // further filter words by making sure no already existing examples get used
                const existingWords = this.examples.map((i) => i.val)
                words = words.filter((i) => !existingWords.includes(i.word))
                this.getExampleSentences(words)
                return true
            }

            if (count > 0) {
                if (flags.exampleSentenceTimer !== null) {
                    clearTimeout(flags.exampleSentenceTimer)
                    flags.exampleSentenceTimer = null
                }
                // eslint-disable-next-line vue/no-async-in-computed-properties
                flags.exampleSentenceTimer = window.setTimeout(funkyWunk, 600)
            }

            return `${count} selected`
        },
        wordsToString() {
            return this.words.map((anItem) => anItem.word)
        }
    },
    destroyed() {
        window.removeEventListener("resize", this.onResize);
    },
    created() {
        window.addEventListener("resize", this.onResize);
        this.onResize()
        console.log('%cCreated Lessons', window.ConsoleStyles.createdComponent, this)
        this.possibleToasts = TOAST_CONFIG
        const tutorialInit = () => {
            this.toasts.push(this.possibleToasts.quizzing)
            this.toasts.push(this.possibleToasts.quizzing2)
            if (this.$mq === 'md+') this.toasts.push(this.possibleToasts.searchFunctionality)
        }

        const CONTEXT = 'lessons'
        getSavedOptions(CONTEXT)
            .then((resultingOpts) => {
                console.log('%cgetUserSettings', window.ConsoleStyles.routine, resultingOpts)
                //  use a specific key
                if (!resultingOpts) return
                Object.keys(resultingOpts).forEach((i) => {
                    this.opts[i] = resultingOpts[i]
                })
            })
            .catch((result) => {
                SendUserAlert(`Failed to fetch user settings: ${result}`, 'alert-warning')
                console.warn('getUserSettings', result)
            })
        getLessons()
            .then(this.init)
            .then(() => {
                if (this.opts.tutorial) tutorialInit()
                this.flags.optionsInitiated = true
            })
            .catch((err) => {
                console.warn('Error during initialization of lessons')
                console.warn(err)
            })
    }
}
</script>

<style lang="sass">
//  font-scaling
#lessonsList
    font-size: 1rem
    .book-card
        font-size: 1rem
        h2
            font-size: 2em
    .user-schedule
        font-size: .75rem
//  dark mode
body
    &.dark
        #lessonsList
            .user-schedule
                h2
                    color: rgba(255, 255, 255, .6)
//  styling
#lessonsList
    .book-card
        &.tutorial
            border-top: 2px dashed rgb(238, 238, 238)
            justify-content: center
    #side
        #lessons-actions
            i:hover
                color: rgba(51, 188, 255, 0.76)!important
</style>
