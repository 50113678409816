/* eslint-disable camelcase */
import { UseAPI, HandleRequestFail } from "@/assets/common"

/* object format
{ card:String, q_state: 0 or 99, readings: same as card? }
*/
export default (arrayofDataObjects) => UseAPI('/create/add-kanji', {
    method: "PUT",
    body: JSON.stringify(
        { items: arrayofDataObjects, match_type: 'kanji', source: 'custom kanji' },
    )
})
.catch((err) => Promise.reject(HandleRequestFail(err)))
