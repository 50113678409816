<template>
    <div class="fl-dropdown --center" v-click-outside="close">
        <div class="fl-dropdown__main">
            <div class="fl-dropdown__title-section">
                <slot name="title-full">
                    <div class="fl-dropdown__title">
                        <slot name="title"></slot>
                    </div>
                    <i class="fas fa-angle-down fl-dropdown__icon"></i>
                </slot>
            </div>
        </div>
        <div class="fl-dropdown__main --extension" :class="isOpen ? '--active' : ''">
            <div class="fl-dropdown__title-section" @click="toggle">
                <slot name="title-full">
                    <div class="fl-dropdown__title">
                        <slot name="title"></slot>
                    </div>
                    <i class="fas fa-angle-down fl-dropdown__icon"></i>
                </slot>
            </div>
            <div class="fl-dropdown__body" v-if="isOpen && anim.titleDone">
                <slot name="body"></slot>
            </div>
        </div>
    </div>
</template>

<script>

const ANIMATION_TIME = 200 // sync with .--active class
export default {
    props: ['forceStatus'],
    data() {
        return {
            isOpen: false,
            anim: {
                titleDone: false,
            },
            timeoutId: 0,
        }
    },
    methods: {
        toggle() {
            if (this.isOpen) this.close()
            else this.open()
        },
        open() {
            this.isOpen = true
            this.timeoutId = setTimeout(() => {
                this.anim.titleDone = true
            }, ANIMATION_TIME)
        },
        close() {
            this.isOpen = false
            this.anim.titleDone = false
            this.destroyOpenAnimationTimeout()
        },
        destroyOpenAnimationTimeout() {
            if (this.timeoutId) window.clearTimeout(this.timeoutId)
        },
    },
    beforeDestroy() {
        this.destroyOpenAnimationTimeout
    },
    watch: {
        forceStatus(v) { // use with $emit to close based on clicking something isnserted into a slot
            if (v === true) this.isOpen = false
        }
    }
}
</script>

<style lang="scss" scoped></style>
